import React, { useEffect, useState } from 'react';
import Footer from '@/components/Footer';
import Logo from '@/assets/logo-full.jpg';
import LoginForm from './form';
import LoginBanner from './banner';
import styles from './style/index.module.less';
import { PageTypeLogin, PageTypeRegister } from './constants';
import RegisterForm from './registForm';
import ForgetForm from './forgetForm';
import { useHistory } from 'react-router-dom';
import LoginBackground from '@/assets/login-background.png';
import LoginLogo from '@/assets/login-logo.png';
import { Image } from '@arco-design/web-react';
import request from '@/request/request';
function Login() {
  const [pageType, setPageType] = useState(PageTypeLogin);
  const history = useHistory();
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    document.body.setAttribute('arco-theme', 'light');
    fetchPromotionInfo();
  }, []);

  useEffect(() => {
    const pgType = +params.get('pageType');
    setPageType(pgType || PageTypeLogin);
  }, [window.location.search]);

  const [promotionInfo, setPromotionInfo] = useState(null);
  // 检查是否有channelId，如果有要加载一下
  const fetchPromotionInfo = async () => {
    const channelId = window.localStorage.getItem('channelId');
    if (channelId) {
      try {
        const response = await request('/apiService/mechanism/promotionInfo', {
          data: {
            channelId: channelId,
          },
          method: 'GET',
        });
        if (response.data) {
          setPromotionInfo(response.data);

          document.title = response.data.assets.seoTitle || '软件造价喵';
        }
      } catch (e) {
        console.warn('e', e);
      } finally {
      }
    }
  };

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${LoginBackground})`,
      }}
    >
      <div className={styles.banner}>
        <div className={styles['banner-inner']}>
          <Image src={LoginLogo} width={800} preview={false} />
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles['content-logo']}>
          {promotionInfo?.logo && (
            <Image
              // src={Logo}
              src={promotionInfo?.logo}
              preview={false}
              style={{
                width: 250,
                height: 75,
                display: 'flex',
              }}
            />
          )}
        </div>
        <div className={styles['content-inner']}>
          {pageType == PageTypeLogin ? (
            <LoginForm
              onTypeChange={(pType) => {
                // 跳转url
                history.replace({
                  pathname: '/login',
                  search: `?pageType=${pType}`,
                });
                setPageType(pType);
              }}
            />
          ) : pageType == PageTypeRegister ? (
            <RegisterForm
              channelName={promotionInfo?.name}
              onTypeChange={(pType) => {
                // 跳转url
                history.replace({
                  pathname: '/login',
                  search: `?pageType=${pType}`,
                });
                setPageType(pType);
              }}
            />
          ) : (
            <ForgetForm
              onTypeChange={(pType) => {
                // 跳转url
                history.replace({
                  pathname: '/login',
                  search: `?pageType=${pType}`,
                });
                setPageType(pType);
              }}
            />
          )}
        </div>
        {/* <div className={styles.footer}>
          <Footer />
        </div> */}
      </div>
    </div>
  );
}
Login.displayName = 'LoginPage';

export default Login;
