import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
  Tooltip,
  Avatar,
  Dropdown,
  Menu,
  Button,
  Modal,
  Space,
  Empty,
  Image,
  Message,
  Badge,
} from '@arco-design/web-react';
import {
  IconSunFill,
  IconMoonFill,
  IconUser,
  IconSettings,
  IconPoweroff,
  IconLoading,
  IconEdit,
  IconMenuUnfold,
  IconMenuFold,
  IconDashboard,
  IconUserGroup,
  IconHome,
  IconTranslate,
  IconPhone,
  IconMobile,
  IconWechat,
} from '@arco-design/web-react/icon';
import { useSelector } from 'react-redux';
import { GlobalState } from '@/store';
import { GlobalContext } from '@/context';
import useLocale from '@/utils/useLocale';
import IconButton from './IconButton';
import Settings from '../Settings';
import styles from './style/index.module.less';
import useStorage from '@/utils/useStorage';
import UpdateUserInfo from './updateUserInfo';
import lazyload from '@/utils/lazyload';
import useRoute, { IRoute } from '@/routes';
import NProgress from 'nprogress';
import getUrlParams from '@/utils/getUrlParams';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import qs from 'query-string';
import { systemSettingGet } from '@/request/api';
import LogoFull from '@/assets/logo-full.jpg';
import request from '@/request/request';

function Navbar({ show }: { show: boolean }) {
  const t = useLocale();
  const { userInfo, userLoading } = useSelector((state: GlobalState) => state);

  const [_, setToken] = useStorage('token');

  const [updateUserInfoVisible, setUpdateUserInfoVisible] = useState(false);

  const { theme, setTheme } = useContext(GlobalContext);

  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [routes, defaultRoute] = useRoute(userInfo?.permissions);
  const urlParams = getUrlParams();
  const history = useHistory();
  const pathname = history.location.pathname;
  const currentComponent = qs.parseUrl(pathname).url.slice(1);
  const locale = useLocale();
  const defaultSelectedKeys = [currentComponent || defaultRoute];
  const [selectedKeys, setSelectedKeys] =
    useState<string[]>(defaultSelectedKeys);
  const flattenRoutes = useMemo(() => getFlattenRoutes(routes) || [], [routes]);
  const paths = (currentComponent || defaultRoute).split('/');
  const defaultOpenKeys = paths.slice(0, paths.length - 1);
  const [openKeys, setOpenKeys] = useState<string[]>(defaultOpenKeys);
  const routeMap = useRef<Map<string, React.ReactNode[]>>(new Map());
  const menuMap = useRef<
    Map<string, { menuItem?: boolean; subMenu?: boolean }>
  >(new Map());

  const MenuItem = Menu.Item;
  const SubMenu = Menu.SubMenu;

  const [contactData, setContactData] = useState(null);
  // 获取系统设置

  const fetchContactData = async () => {
    const response = await request('/apiService/mechanism/assets', {
      data: {},
      method: 'GET',
    });
    setContactData(response.data.assets);

    document.title = response.data.assets.seoTitle || '软件造价喵';

    // const response = await systemSettingGet({
    //   keys: [
    //     'CompanyPhoneNumber',
    //     'CompanyWechatAccountQrCode',
    //     'CompanyServiceQrCode',
    //     'HelpDocumentUrl',
    //     'HelpVideoUrl',
    //   ],
    // });
    // const systemSettingMap = {};
    // (response.data.list || []).forEach((item) => {
    //   systemSettingMap[item.key] = item.value;
    // });
    // setContactData(systemSettingMap);
  };

  useEffect(() => {
    updateMenuStatus();
    fetchContactData();
  }, [pathname]);

  function toggleCollapse() {
    setCollapsed((collapsed) => !collapsed);
  }
  function logout() {
    setToken('');
    window.location.href = '/login';
  }

  function onMenuItemClick(key) {
    if (key === 'logout') {
      logout();
    }
    if (key === 'updatePassword') {
      setUpdateUserInfoVisible(true);
    }
  }

  if (!show) {
    return (
      <div className={styles['fixed-settings']}>
        <Settings
          trigger={
            <Button icon={<IconSettings />} type="primary" size="large" />
          }
        />
      </div>
    );
  }

  const droplist = (
    <Menu onClickMenuItem={onMenuItemClick}>
      <Menu.Item key="updatePassword">
        <IconEdit className={styles['dropdown-icon']} />
        修改密码
      </Menu.Item>
      <Menu.Item key="logout">
        <IconPoweroff className={styles['dropdown-icon']} />
        退出登录
      </Menu.Item>
    </Menu>
  );

  function getFlattenRoutes(routes) {
    const res = [];
    function travel(_routes) {
      _routes.forEach((route) => {
        const visibleChildren = (route.children || []).filter(
          (child) => !child.ignore
        );
        if (route.key && (!route.children || !visibleChildren.length)) {
          try {
            route.component = lazyload(
              () => import(`./../../pages/${route.key}`)
            );
            res.push(route);
          } catch (e) {
            console.error(e);
          }
        }
        if (route.children && route.children.length) {
          travel(route.children);
        }
      });
    }
    travel(routes);
    return res;
  }

  function handleContactClick() {
    if (
      !contactData?.companyPhoneNumber &&
      !contactData?.companyServiceQrCode &&
      !contactData.companyWechatAccountQrCode
    ) {
      Modal.info({
        title: '联系我们',
        footer: null,
        content: (
          <>
            <Empty description="管理员暂未配置联系方式" />
          </>
        ),
      });
    } else {
      Modal.info({
        title: '联系客服',
        footer: null,
        content: (
          <>
            {contactData?.companyPhoneNumber && (
              <div style={{ marginBottom: 50 }}>
                <Space>
                  <IconMobile style={{ fontSize: 24 }} />
                  <div style={{ fontSize: 16, width: 100 }}>联系电话</div>
                  <div style={{ fontSize: 16 }}>
                    {contactData?.companyPhoneNumber}
                  </div>
                </Space>
              </div>
            )}
            {contactData?.companyServiceQrCode && (
              <div style={{ marginBottom: 50 }}>
                <Space style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <IconWechat style={{ fontSize: 24, color: '#1aad19' }} />
                  <div style={{ fontSize: 16, width: 100 }}>客服微信</div>
                  <div>
                    <img
                      src={contactData?.companyServiceQrCode}
                      style={{
                        background: 'var(--text-color-1)',
                        width: 150,
                        height: 150,
                      }}
                    />
                  </div>
                </Space>
              </div>
            )}
            {contactData?.companyWechatAccountQrCode && (
              <div style={{ marginBottom: 50 }}>
                <Space style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <IconWechat style={{ fontSize: 24, color: '#1aad19' }} />
                  <div style={{ fontSize: 16, width: 100 }}>微信公众号</div>
                  <div>
                    <img
                      src={contactData?.companyWechatAccountQrCode}
                      style={{
                        background: 'var(--text-color-1)',
                        width: 150,
                        height: 150,
                      }}
                    />
                  </div>
                </Space>
              </div>
            )}
          </>
        ),
      });
    }
  }

  async function onClickMenuItem(key) {
    if (key == 'service') {
      handleContactClick();
      return;
    }
    if (key == 'help') {
      if (contactData?.helpDocumentUrl) {
        window.open(contactData?.helpDocumentUrl);
        return;
      }
      return Message.info('暂无帮助文档');
    }
    if (key == 'helpVideo') {
      if (contactData?.helpVideoUrl) {
        window.open(contactData?.helpVideoUrl);
        return;
      }
      return Message.info('暂无演示视频');
    }
    if (key == 'expert') {
      try {
        const response = await request('/apiService/mechanism/platformToken', {
          data: {},
          method: 'GET',
        });
        if (response.data.token) {
          window.open(
            // process.env.EXPERT_APP_DOMAIN +
            'https://www.szzjk.cn' + '/index?token=' + response.data.token
          );
        } else {
          window.open(
            // process.env.EXPERT_APP_DOMAIN +
            'https://www.szzjk.cn/index'
          );
        }
      } catch (e) {}
      return;
    }
    const currentRoute = flattenRoutes.find((r) => r.key === key);
    const component = currentRoute.component;
    const preload = component.preload();
    NProgress.start();
    preload.then(() => {
      history.push(currentRoute.path ? currentRoute.path : `/${key}`);
      NProgress.done();
    });
  }

  function getIconFromKey(key) {
    switch (key) {
      // case 'project':
      //   return <IconHome className={styles.icon} />;
      // case 'finance':
      //   return <IconTranslate className={styles.icon} />;
      // case 'parameter':
      //   return <IconSettings className={styles.icon} />;
      default:
        return <div className={styles['icon-empty']} />;
    }
  }

  function renderRoutes(locale) {
    routeMap.current.clear();
    return function travel(_routes: IRoute[], level, parentNode = []) {
      return _routes.map((route) => {
        const { breadcrumb = true, ignore } = route;
        const iconDom = getIconFromKey(route.key);
        const titleDom = (
          <>
            {iconDom}
            {route.key === 'inquiry/projectQuickly/create' ? (
              <Badge
                text="Alpha版"
                offset={[5, -15]}
                dotStyle={{ background: '#E5E6EB', color: '#86909C' }}
              >
                {locale[route.name] || route.name}
              </Badge>
            ) : (
              locale[route.name] || route.name
            )}
          </>
        );

        routeMap.current.set(
          `/${route.key}`,
          breadcrumb ? [...parentNode, route.name] : []
        );

        const visibleChildren = (route.children || []).filter((child) => {
          const { ignore, breadcrumb = true } = child;
          if (ignore || route.ignore) {
            routeMap.current.set(
              `/${child.key}`,
              breadcrumb ? [...parentNode, route.name, child.name] : []
            );
          }

          return !ignore;
        });

        if (ignore) {
          return '';
        }
        if (visibleChildren.length) {
          menuMap.current.set(route.key, { subMenu: true });
          return (
            <SubMenu key={route.key} title={titleDom}>
              {travel(visibleChildren, level + 1, [...parentNode, route.name])}
            </SubMenu>
          );
        }
        menuMap.current.set(route.key, { menuItem: true });
        return <MenuItem key={route.key}>{titleDom}</MenuItem>;
      });
    };
  }

  function updateMenuStatus() {
    const pathKeys = pathname.split('/');
    const newSelectedKeys: string[] = [];
    const newOpenKeys: string[] = [...openKeys];
    while (pathKeys.length > 0) {
      const currentRouteKey = pathKeys.join('/');
      const menuKey = currentRouteKey.replace(/^\//, '');
      const menuType = menuMap.current.get(menuKey);
      if (menuType && menuType.menuItem) {
        newSelectedKeys.push(menuKey);
      }
      if (menuType && menuType.subMenu && !openKeys.includes(menuKey)) {
        newOpenKeys.push(menuKey);
      }
      pathKeys.pop();
    }
    setSelectedKeys(newSelectedKeys);
    setOpenKeys(newOpenKeys);
  }

  return (
    <div className={styles.navbar}>
      <div className={styles.left}>
        <div className={styles.logo}>
          {contactData?.logo && (
            <Image
              // src={Logo}
              src={contactData?.logo}
              preview={false}
              style={{
                width: 180,
                height: 54,
                display: 'flex',
              }}
            />
            // <Image src={LogoFull} width={200} preview={false} />
          )}
        </div>
        <div style={{ width: '900px' }}>
          <Menu
            mode="horizontal"
            onClickMenuItem={onClickMenuItem}
            selectedKeys={selectedKeys}
            openKeys={openKeys}
            onClickSubMenu={(_, openKeys) => setOpenKeys(openKeys)}
          >
            {renderRoutes(locale)(routes, 1)}
          </Menu>
        </div>
      </div>
      <ul className={styles.right}>
        <li>
          <Tooltip
            content={
              theme === 'light'
                ? t['settings.navbar.theme.toDark']
                : t['settings.navbar.theme.toLight']
            }
          >
            <IconButton
              icon={theme !== 'dark' ? <IconMoonFill /> : <IconSunFill />}
              onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
            />
          </Tooltip>
        </li>
        {/* <Settings /> */}
        {userInfo && (
          <li>
            {userInfo?.registSource === 2 ? (
              <Space style={{ cursor: 'pointer' }}>
                <Avatar size={32}>
                  {userLoading ? <IconLoading /> : <IconUser />}
                </Avatar>
                <span
                  style={{
                    color: 'var(--color-text-1)',
                  }}
                >
                  {userInfo.nickname}
                </span>
              </Space>
            ) : (
              <Dropdown
                droplist={droplist}
                position="br"
                disabled={userLoading}
              >
                <Space style={{ cursor: 'pointer' }}>
                  <Avatar size={32}>
                    {userLoading ? <IconLoading /> : <IconUser />}
                  </Avatar>
                  <span
                    style={{
                      color: 'var(--color-text-1)',
                    }}
                  >
                    {userInfo.nickname}
                  </span>
                </Space>
              </Dropdown>
            )}
          </li>
        )}
      </ul>
      <Modal
        title={'修改密码'}
        visible={updateUserInfoVisible}
        footer={null}
        style={{ width: 800 }}
        unmountOnExit
        maskClosable={false}
        focusLock={false}
        onCancel={() => {
          setUpdateUserInfoVisible(false);
        }}
      >
        <UpdateUserInfo
          onClose={() => {
            setUpdateUserInfoVisible(false);
          }}
          onSuccess={() => {
            setUpdateUserInfoVisible(false);
            logout();
          }}
        />
      </Modal>
    </div>
  );
}

export default Navbar;
